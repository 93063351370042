import {createSlice} from '@reduxjs/toolkit';
import {User} from 'hubbl-shared';
import {fetchUserDetails} from './UserThunks';

interface UserState {
  userDetails: User | null;
}

const initialState: UserState = {
  userDetails: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // setUserDetails: (state, action: PayloadAction<User>) => {
    //   state.userDetails = action.payload;
    // },
    resetUserDetails: state => {
      state.userDetails = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.userDetails = action.payload;
    });
  },
});

export const {resetUserDetails} = userSlice.actions;

export const UserReducer = userSlice.reducer;
