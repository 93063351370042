import {ApideckVault, Connection} from '@apideck/vault-js';
import {Palette} from 'hubbl-shared';
import {memo, useCallback} from 'react';
import styled from 'styled-components';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {CompanyActions} from '../../store/company/CompanyActions';
import {CompanySelector} from '../../store/company/CompanySelector';
import {IconButton} from '../buttons/icon-button/IconButton';
import {AssetIconMenu} from '../../assets';

const StyledTopbar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2;
  background-color: ${Palette.primarySubtleGreen};
`;

export const Topbar = memo(() => {
  const dispatch = useAppDispatch();

  const session = useAppSelector(CompanySelector.getApideckSession);
  const companyDetails = useAppSelector(CompanySelector.getCompanyDetails);

  const onConnectionChange = useCallback(
    (connection: Connection) => {
      console.log(
        '🚀🚀🚀🚀 ~ file: Topbar.tsx:41 ~ onClickVault ~ connection:',
        connection,
      );

      dispatch(CompanyActions.addHRIStoCompany(connection));
    },
    [dispatch],
  );

  const onClickVault = useCallback(() => {
    if (!session.session_token) {
      return;
    }
    ApideckVault.open({
      token: session.session_token,
      showAttribution: false,
      onConnectionChange,
    });
  }, [session.session_token, onConnectionChange]);
  return (
    <StyledTopbar>
      {companyDetails?.settings.connectedHRIS &&
        `Integrated with ${companyDetails.settings.connectedHRIS.name}`}
      <IconButton onClick={onClickVault} icon={<AssetIconMenu />}></IconButton>
    </StyledTopbar>
  );
});
