import {UserCredential, signInWithEmailAndPassword} from 'firebase/auth';
import {doc, getDoc} from 'firebase/firestore';
import {FirestoreCollection, User} from 'hubbl-shared';
import {UserDTO} from '../models';
import {firebaseAuth, firestore} from './firebase';
import {mapUserDTOToUser} from './utils/map-incoming-users/mapUserDTOToUser';

const getUserDetails = async (
  companyId: string,
  authUserId: string,
): Promise<User | null> => {
  const userPromise = new Promise<User | null>(async (resolve, reject) => {
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );
      const userRef = doc(companyRef, FirestoreCollection.Users, authUserId);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        //TODO: PARSE CREATED AT LIKE IN APP
        resolve(mapUserDTOToUser(userSnapshot.data() as UserDTO));
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
  return userPromise;
};

const onLogIn = async (
  email: string,
  password: string,
): Promise<UserCredential> => {
  return signInWithEmailAndPassword(firebaseAuth, email, password)
    .then(authUser => {
      return authUser;
    })
    .catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      throw error(errorCode, errorMessage);
    });
};

const onLogOut = async () => {
  return firebaseAuth.signOut();
};

export const user = {
  getUserDetails,
  onLogIn,
  onLogOut,
};
