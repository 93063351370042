import {InterestStatisticHubbl} from 'hubbl-shared';
import {InterestStatisticDTO} from '../../../models';
import {mapUserDTOToUser} from '../map-incoming-users/mapUserDTOToUser';

export const mapInterestStatisticDTOToInterestStatistic = (
  InterestStatisticDTO: InterestStatisticDTO,
): InterestStatisticHubbl => {
  return {
    ...InterestStatisticDTO,
    usersWithInterest: InterestStatisticDTO.usersWithInterest.map(dto =>
      mapUserDTOToUser(dto),
    ),
  } as InterestStatisticHubbl;
};
