import {Palette} from 'hubbl-shared';
import {memo} from 'react';
import {FontConfig} from '../../constants/FontConfig';

import {Text} from './Text';
import {TextProps} from './types';

export const TextHeading3 = memo(
  ({text, style, color = Palette.almostBlack, ...props}: TextProps) => {
    return (
      <Text
        text={text}
        style={{
          color,
          ...FontConfig.Heading3,
          ...style,
        }}
        {...props}
      />
    );
  },
);
