import {Api} from '../../api';
import {getAuthUser, getAuthUserCompanyId} from '../../utils';
import {createAppAsyncThunk} from '../appAsyncThunk';

export const fetchUserDetails = createAppAsyncThunk(
  'user/fetchUserDetails',
  async () => {
    const userCompanyId = await getAuthUserCompanyId();

    const userDetails = await Api.user.getUserDetails(
      userCompanyId,
      getAuthUser()?.uid ?? '',
    );

    return userDetails;
  },
);
