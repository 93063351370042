import {Connection} from '@apideck/vault-js';
import {HRIS} from 'hubbl-shared';
import {Api} from '../../api';
import {getAuthUserCompanyId} from '../../utils';
import {createAppAsyncThunk} from '../appAsyncThunk';
import {UserSelector} from '../user/UserSelector';

export const addHRIStoCompany = createAppAsyncThunk<HRIS, Connection>(
  'company/addHRIStoCompany',
  async (hrisConnection, {getState}) => {
    if (!hrisConnection.enabled || hrisConnection.state !== 'callable') {
      throw Error('error - connection not enabled or not callable. No succes');
    }
    const userDetails = UserSelector.getUserDetails(getState());

    if (!userDetails) {
      throw Error('error - no user details found');
    }

    try {
      const userCompanyId = await getAuthUserCompanyId();

      const newHRIS: HRIS = {
        createdAtMilliseconds:
          hrisConnection.created_at ?? new Date().getMilliseconds(),
        icon: hrisConnection.icon ?? '',
        logo: hrisConnection.logo ?? '',
        id: hrisConnection.id,
        integratedByUserId: userDetails.id,
        name: hrisConnection.name,
        website: hrisConnection.website ?? '',
      };

      await Api.company.addHRIStoCompany(userCompanyId, newHRIS);

      return newHRIS;
    } catch (error) {
      console.log(error);
      throw Error('error adding the HRIS to company');
    }
  },
);

export const fetchCompany = createAppAsyncThunk(
  'company/fetchCompany',
  async () => {
    const userCompanyId = await getAuthUserCompanyId();

    const companyDetails =
      await Api.company.getLoggedInUserCompany(userCompanyId);

    return companyDetails;
  },
);

export const fetchCompanyUsers = createAppAsyncThunk(
  'company/fetchCompanyUsers',
  async () => {
    const userCompanyId = await getAuthUserCompanyId();

    const companyUsers = await Api.company.getCompanyUsers(userCompanyId);

    return companyUsers;
  },
);
