import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '..';

const getCompanyDetails = createSelector(
  (state: RootState) => state.company.companyDetails,
  companyDetails => companyDetails,
);
const getCompanyUsers = createSelector(
  (state: RootState) => state.company.companyUsers,
  companyUsers => companyUsers,
);
const getApideckSession = createSelector(
  (state: RootState) => state.company.apideckSession,
  session => session,
);

export const CompanySelector = {
  getCompanyDetails,
  getApideckSession,
  getCompanyUsers,
};
