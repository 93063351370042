import {Palette} from 'hubbl-shared';
import {ReactNode, memo} from 'react';
import styled from 'styled-components';
import {PaddingRight} from '../../padding/PaddingRight';
import {TextHeading7} from '../../text/TextHeading7';

interface IconButtonProps {
  onClick: () => void;
  icon: ReactNode;
  text?: string;
  color?: Palette;
  hoverColor?: Palette;
}

const Button = styled.button<Pick<IconButtonProps, 'hoverColor'>>`
  background-color: ${Palette.transparent};
  padding: 5px 10px;
  border-radius: 5px;
  border-width: 0px;
  outline: 0;
  cursor: pointer;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${props =>
      props.hoverColor ? props.hoverColor : Palette.primaryCherokee};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconButton = memo(
  ({icon, onClick, text, color, hoverColor}: IconButtonProps) => {
    return (
      <Button hoverColor={hoverColor} onClick={onClick}>
        {icon}
        {text && (
          <>
            <PaddingRight amount={12} />
            <TextHeading7 text={text} color={color} />
          </>
        )}
      </Button>
    );
  },
);
