import React from 'react';
import {MenuItem} from 'react-pro-sidebar';
import {Link} from 'react-router-dom';
import {AdminWebRoute} from '../../../../../routes/AdminWebRoute';
import {TextHeading5} from '../../../../text/TextHeading5';
import {AssetIconChevron} from '../../../../../assets';

interface SidebarItemProps {
  title: string;
  to: AdminWebRoute;
  icon: React.ReactNode;
  currentRoute: AdminWebRoute;
  onClick: () => void;
}

export const SidebarItem = ({
  icon,
  currentRoute,
  onClick,
  title,
  to,
}: SidebarItemProps) => {
  return (
    <Link style={{textDecoration: 'none'}} to={to}>
      <MenuItem
        suffix={
          <div
            style={{
              height: 20,
            }}>
            <AssetIconChevron />
          </div>
        }
        style={{height: 72, transition: 'ease background-color 250ms'}}
        active={currentRoute === to}
        icon={icon}
        onClick={onClick}>
        <TextHeading5 text={title}></TextHeading5>
      </MenuItem>
    </Link>
  );
};
