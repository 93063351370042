import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {Company, User} from 'hubbl-shared';
import {
  addHRIStoCompany,
  fetchCompany,
  fetchCompanyUsers,
} from './CompanyThunks';

interface CompanyState {
  companyUsers: User[];
  companyDetails: Company | null;
  apideckSession: {
    session_token: string | null;
    session_uri: string | null;
  };
}

const initialState: CompanyState = {
  companyUsers: [],
  companyDetails: null,
  apideckSession: {
    session_token: null,
    session_uri: null,
  },
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // setUserDetails: (state, action: PayloadAction<User>) => {
    //   state.userDetails = action.payload;
    // },
    resetCompanyDetails: state => {
      state.companyUsers = [];
      state.companyDetails = null;
    },
    setApideckSession: (
      state,
      action: PayloadAction<{session_token: string; session_uri: string}>,
    ) => {
      state.apideckSession = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCompany.fulfilled, (state, action) => {
      state.companyDetails = action.payload;
    });
    builder.addCase(fetchCompanyUsers.fulfilled, (state, action) => {
      state.companyUsers = action.payload;
    });
    builder.addCase(addHRIStoCompany.fulfilled, (state, action) => {
      if (state.companyDetails) {
        state.companyDetails.settings.connectedHRIS = action.payload;
      }
    });
  },
});

export const {resetCompanyDetails, setApideckSession} = companySlice.actions;

export const CompanyReducer = companySlice.reducer;
