import {yupResolver} from '@hookform/resolvers/yup';
import {InterestStatisticHubbl} from 'hubbl-shared';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import {Api} from '../../../../api';
import {PrimaryButton, Scene, TextHeading3} from '../../../../components';
import {useAppSelector} from '../../../../hooks';
import {CompanySelector} from '../../../../store/company/CompanySelector';
import {UserSelector} from '../../../../store/user/UserSelector';

// yup schema
const schema = yup.object().shape({
  interestStatisticId: yup
    .string()
    .required('interestStatisticId is required.'),
  userIds: yup.string().required('User Ids is required.'),
});

interface IFormInput {
  interestStatisticId: string;
  userIds: string;
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  height: 35px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;
const StyledSubmitButton = styled.input.attrs({
  type: 'submit',
})`
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #0056b3;
  }
`;

const StyledContainer = styled.div`
  display: flex;
`;

export const SceneAddInterestStatistic = () => {
  //  const companyUsers = useAppSelector(CompanySelector.getCompanyUsers);
  const userDetails = useAppSelector(UserSelector.getUserDetails);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const companyUsers = useAppSelector(CompanySelector.getCompanyUsers);

  const onCreateHubblSuggestion = useCallback(
    async ({interestStatisticId, userIds}: IFormInput) => {
      const userIdArray = userIds.split(',');

      const filteredUsers = companyUsers.filter(user => {
        return userIdArray.some(userId => userId === user.id);
      });

      console.log('🚀🚀🚀🚀 ~ filteredUsers ~ filteredUsers:', filteredUsers);

      if (filteredUsers.length === 0 || !userDetails) {
        return;
      }
      const interestStatistic = await Api.superAdmin.getInterestStatisticById(
        userDetails.companyId,
        interestStatisticId,
      );

      if (!interestStatistic) {
        return;
      }

      const updatedInterestStatistic: InterestStatisticHubbl = {
        ...interestStatistic,
        usersWithInterest: filteredUsers,
      };

      await Api.superAdmin.updateInterestStatistic(
        userDetails.companyId,
        updatedInterestStatistic,
      );
    },
    [companyUsers, userDetails],
  );

  const onAddInterestStatistic = useCallback(async () => {
    if (!userDetails) {
      return;
    }
    await Api.superAdmin.addInterestStatistic(userDetails.companyId);
  }, [userDetails]);

  return (
    <Scene>
      <PrimaryButton
        label="Add dummy interest statistic"
        onClick={onAddInterestStatistic}></PrimaryButton>
      <StyledContainer>
        <StyledForm onSubmit={handleSubmit(onCreateHubblSuggestion)}>
          <TextHeading3 text={'Create interest statistic'} />
          <StyledLabel>interestStatistic ID</StyledLabel>
          <StyledInput {...register('interestStatisticId')} />
          {errors.interestStatisticId && (
            <p>{errors.interestStatisticId.message}</p>
          )}

          <StyledLabel>user ids</StyledLabel>
          <StyledInput {...register('userIds')} />
          {errors.userIds && <p>{errors.userIds.message}</p>}

          <StyledSubmitButton type="submit" />
        </StyledForm>
      </StyledContainer>
    </Scene>
  );
};
