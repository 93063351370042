import {User} from 'hubbl-shared';
import {UserDTO} from '../../../models';

export const mapUserDTOToUser = (userDTO: UserDTO): User => {
  return {
    ...userDTO,
    startedAtCompany: userDTO.startedAtCompany
      ? userDTO.startedAtCompany.toDate().toISOString()
      : undefined,
    connectedHRISInfo: userDTO.connectedHRISInfo
      ? {
          ...userDTO.connectedHRISInfo,
          birthday: userDTO.connectedHRISInfo.birthday
            ? userDTO.connectedHRISInfo.birthday.toDate().toISOString()
            : null,
        }
      : null,
  } as User;
};
