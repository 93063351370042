import {COMPANY_ID_CLAIM} from 'hubbl-shared';
import {getAuthUser} from './getAuthUser';

export const getAuthUserCompanyId = async (): Promise<string> => {
  const authUser = getAuthUser();

  const companyId = authUser
    ? ((await (
        await authUser.getIdTokenResult(true)
      ).claims[COMPANY_ID_CLAIM]) as string)
    : //TODO: THROW ERROR IN PLACES, IF THIS IS WHAT IS RETURNED!!!
      '';

  return companyId;
};
