import {RootState} from '..';
import {createSelector} from '@reduxjs/toolkit';

const getUserDetails = createSelector(
  (state: RootState) => state.user.userDetails,
  userDetails => userDetails,
);

export const UserSelector = {
  getUserDetails,
};
