import {InterestStatisticHubbl} from 'hubbl-shared';
import {InterestStatisticDTO} from '../../../models';
import {mapUserToUserDTO} from '../map-incoming-users/mapUserToUserDTO';

export const mapInterestStatisticToInterestStatisticDTO = (
  interestStatistic: InterestStatisticHubbl,
): InterestStatisticDTO => {
  return {
    ...interestStatistic,
    usersWithInterest: interestStatistic.usersWithInterest.map(user =>
      mapUserToUserDTO(user),
    ),
  };
};
