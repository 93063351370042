import {ReactNode, memo} from 'react';
import styled from 'styled-components';

interface SceneProps {
  children: ReactNode;
}

const StyledScene = styled.div`
  padding-top: 24px;
  padding-left: 5%;
  padding-right: 5%;
`;

export const Scene = memo(({children}: SceneProps) => {
  return <StyledScene>{children}</StyledScene>;
});
