import {ReactElement} from 'react';
import {RootRoute, WebRoute} from './RootRoute';

import {Navigate, Outlet} from 'react-router-dom';
import {useAuthUser} from '../hooks/useAuthUser';
interface ProtectedRouteProps {
  redirectPath?: WebRoute;
  children?: ReactElement;
}

export const ProtectedRoute = ({
  redirectPath = RootRoute.LogIn,
  children,
}: ProtectedRouteProps) => {
  const authUser = useAuthUser();
  if (!authUser) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
