import {Palette} from 'hubbl-shared';
import {MenuItem} from 'react-pro-sidebar';
import styled from 'styled-components';
import {AssetIconMenu} from '../../../../assets';
import {AppLogo} from '../../../../assets/branding/AssetAppLogo';

interface MenuItemsContainerProps {
  isCollapsed: boolean;
  onClick: () => void;
}
const SidebarTopMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
`;

export const SidebarMenuHeader = ({
  isCollapsed,
  onClick,
}: MenuItemsContainerProps) => {
  return (
    <MenuItem
      onClick={onClick}
      icon={isCollapsed ? <AssetIconMenu /> : undefined}
      style={{margin: '10px 0 20px 0', color: Palette.almostBlack500}}>
      {!isCollapsed && (
        <SidebarTopMenuContainer>
          <AppLogo size={100} />
          <AssetIconMenu />
        </SidebarTopMenuContainer>
      )}
    </MenuItem>
  );
};
