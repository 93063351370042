import {COMPANY_ADMIN_CLAIM} from 'hubbl-shared';
import {getAuthUser} from './getAuthUser';
import {User} from 'firebase/auth';

export const getAuthUserIsAdmin = async (
  checkUser?: User,
): Promise<boolean> => {
  const authUser = checkUser ?? getAuthUser();

  const isAdmin = authUser
    ? ((await (
        await authUser.getIdTokenResult(true)
      ).claims[COMPANY_ADMIN_CLAIM]) as boolean)
    : //TODO: THROW ERROR IN PLACES, IF THIS IS WHAT IS RETURNED!!!
      false;

  return isAdmin;
};
