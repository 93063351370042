import {Palette} from 'hubbl-shared';
import {AssetIconProps} from '../types';
import {memo} from 'react';

export const AssetIconMenu = memo(
  ({size = 32, color = Palette.almostBlack}: AssetIconProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="7.5"
          stroke={color}
          stroke-opacity="0.1"
        />
        <path d="M11 12H21" stroke={color} stroke-linecap="round" />
        <path d="M11 16H21" stroke={color} stroke-linecap="round" />
        <path d="M11 20H21" stroke={color} stroke-linecap="round" />
      </svg>
    );
  },
);
