import {memo} from 'react';
import styled from 'styled-components';
import {TextProps} from './types';

const StyledText = styled.div<
  Pick<TextProps, 'center' | 'underline' | 'opacity'>
>`
  ${props => props.center && 'text-align: center;'}
  ${props => props.underline && 'text-decoration: underline;'}
  opacity: ${props => props.opacity};
`;

export const Text = memo(
  ({text, style, underline, center, opacity}: TextProps) => {
    return (
      <StyledText
        style={style}
        center={center}
        underline={underline}
        opacity={opacity}>
        {text}
      </StyledText>
    );
  },
);
