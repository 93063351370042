import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import {Api} from '../../../../api';
import {PrimaryButton, Scene, TextHeading3} from '../../../../components';
import {useAppSelector} from '../../../../hooks';
import {UserSelector} from '../../../../store/user/UserSelector';
import {NotificationAction, NotificationDataType} from 'hubbl-shared';

enum DropDownType {
  LandingPage = 'landingPage',
  Connect = 'connect',
  Group = 'group',
  Event = 'event',
  InterestStatistic = 'interestStatistic',
  InviteUser = 'inviteUser',
  InviteUserConnect = 'inviteUserConnect',
  InviteUserGroup = 'inviteUserGroup',
  InviteUserInstant = 'inviteUserInstant',
}

// yup schema
const schema = yup.object().shape({
  title: yup.string().required('Title is a required field'),
  description: yup.string().required('description is required.'),
  companyId: yup.string().required('company ID is required.'),
  //Recipients
  userIds: yup.string(),
  deeplinkId: yup.string(),
  deeplinkType: yup.string().required(),
});
const schemaMoveAdminToCompany = yup.object().shape({
  companyId: yup.string().required('company ID is required.'),
  userId: yup.string().required('userId is required.'),
  deleteOldUser: yup.boolean().required('delete old user is required'),
});

interface IFormInput {
  title: string;
  description: string;
  companyId: string;
  userIds?: string;
  deeplinkType: string;
  deeplinkId?: string;
}
interface IFormInputMoveAdminToCompany {
  userId: string;
  deleteOldUser: boolean;
  companyId: string;
}
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;
const StyledFormMoveAdmin = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  height: 35px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;
const StyledCheckBox = styled.input.attrs({type: 'checkbox'})`
  height: 35px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;
const StyledSubmitButton = styled.input.attrs({
  type: 'submit',
})`
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #0056b3;
  }
`;

const StyledContainer = styled.div`
  display: flex;
`;

export const SceneDashboard = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const {
    register: registerMoveAdminToCompany,
    handleSubmit: handleSubmitMoveAdminToCompany,
    formState: {errors: errorsMoveAdminToCompany},
  } = useForm<IFormInputMoveAdminToCompany>({
    resolver: yupResolver(schemaMoveAdminToCompany),
  });
  const userDetails = useAppSelector(UserSelector.getUserDetails);
  const onClick = useCallback(async () => {
    await Api.hris.getApideckSession();
  }, []);
  const onClickAddMondayNotification = useCallback(async () => {
    await Api.superAdmin.addMondayNotification();
  }, []);
  const onClickTestScheduler = useCallback(async () => {
    await Api.superAdmin.testScheduler();
  }, []);

  const onClickAddEvent = useCallback(async () => {
    if (!userDetails) {
      return;
    }
    await Api.superAdmin.addEvent(
      userDetails.companyId,
      userDetails.settings.currentOfficeId,
    );
  }, [userDetails]);

  const onSendNotificationToCompany = useCallback(
    ({
      description,
      title,
      companyId,
      userIds,
      deeplinkType,
      deeplinkId,
    }: IFormInput) => {
      let userIdArray: string[] = [];

      if (userIds) {
        userIds.split(',').forEach(userId => userIdArray.push(userId));
      }

      let notificationData: NotificationDataType = {
        type: NotificationAction.DEEPLINK_LANDING_PAGE,
      };

      if (deeplinkType === DropDownType.Connect && deeplinkId) {
        notificationData = {
          type: NotificationAction.DEEPLINK_BREAK_DETAILS,
          breakId: deeplinkId.toString(),
        };
      } else if (deeplinkType === DropDownType.Group && deeplinkId) {
        notificationData = {
          type: NotificationAction.DEEPLINK_HUBBL_DETAILS,
          hubblId: deeplinkId.toString(),
        };
      } else if (deeplinkType === DropDownType.Event && deeplinkId) {
        notificationData = {
          type: NotificationAction.DEEPLINK_EVENT_CHAT,
          eventId: deeplinkId.toString(),
          colleaguePhotoUrl: '',
        };
      } else if (deeplinkType === DropDownType.InviteUser && deeplinkId) {
        notificationData = {
          type: NotificationAction.DEEPLINK_INVITE_USER,
          userId: deeplinkId,
        };
      } else if (
        deeplinkType === DropDownType.InviteUserConnect &&
        deeplinkId
      ) {
        notificationData = {
          type: NotificationAction.DEEPLINK_INVITE_USER,
          userId: deeplinkId,
          hubblType: 'connect',
        };
      } else if (deeplinkType === DropDownType.InviteUserGroup && deeplinkId) {
        notificationData = {
          type: NotificationAction.DEEPLINK_INVITE_USER,
          userId: deeplinkId,
          hubblType: 'group',
        };
      } else if (
        deeplinkType === DropDownType.InviteUserInstant &&
        deeplinkId
      ) {
        notificationData = {
          type: NotificationAction.DEEPLINK_INVITE_USER,
          userId: deeplinkId,
          hubblType: 'instant',
        };
      } else if (
        deeplinkType === DropDownType.InterestStatistic &&
        deeplinkId
      ) {
        notificationData = {
          type: NotificationAction.DEEPLINK_INTEREST_STATISTIC_CHAT,
          interestStatisticId: deeplinkId.toString(),
          colleaguePhotoUrl: '',
        };
      }

      Api.superAdmin.sendNotificationToCompany(
        companyId,
        title,
        description,
        notificationData,
        userIdArray,
      );
    },
    [],
  );
  const onMoveAdminToCompany = useCallback(
    ({companyId, deleteOldUser, userId}: IFormInputMoveAdminToCompany) => {
      console.log(
        '🚀🚀🚀🚀 ~ SceneDashboard ~ companyId, deleteOldUser, userId:',
        companyId,
        deleteOldUser,
        userId,
      );
      Api.superAdmin.onMoveAdminToCompany(companyId, deleteOldUser, userId);
    },
    [],
  );

  const deeplinkType = register('deeplinkType');

  return (
    <Scene>
      <div style={{paddingBottom: 50}}>
        <PrimaryButton
          label="Test monday notifications"
          onClick={onClick}></PrimaryButton>
        <PrimaryButton
          label="Add monday notification"
          onClick={onClickAddMondayNotification}></PrimaryButton>
        <PrimaryButton
          label="Add event"
          onClick={onClickAddEvent}></PrimaryButton>
        <PrimaryButton
          label="Test scheduler"
          onClick={onClickTestScheduler}></PrimaryButton>
      </div>

      <StyledFormMoveAdmin
        onSubmit={handleSubmitMoveAdminToCompany(onMoveAdminToCompany)}>
        <TextHeading3 text={'Move admin to other company'} />
        <StyledLabel>Company ID</StyledLabel>
        <StyledInput {...registerMoveAdminToCompany('companyId')} />
        {errorsMoveAdminToCompany.companyId && (
          <p>{errorsMoveAdminToCompany.companyId.message}</p>
        )}
        <StyledLabel>User ID</StyledLabel>
        <StyledInput {...registerMoveAdminToCompany('userId')} />
        {errorsMoveAdminToCompany.userId && (
          <p>{errorsMoveAdminToCompany.userId.message}</p>
        )}
        <StyledLabel>Delete old user?</StyledLabel>
        <StyledCheckBox {...registerMoveAdminToCompany('deleteOldUser')} />
        {errorsMoveAdminToCompany.deleteOldUser && (
          <p>{errorsMoveAdminToCompany.deleteOldUser.message}</p>
        )}

        <StyledSubmitButton type="submit" />
      </StyledFormMoveAdmin>

      <StyledContainer>
        <StyledForm onSubmit={handleSubmit(onSendNotificationToCompany)}>
          <TextHeading3 text={'Send Notification to all in company'} />
          <StyledLabel>Company ID</StyledLabel>
          <StyledInput {...register('companyId')} />
          {errors.companyId && <p>{errors.companyId.message}</p>}
          <StyledLabel>Notification title</StyledLabel>
          <StyledInput {...register('title')} />
          {errors.title && <p>{errors.title.message}</p>}
          <StyledLabel>Notification description</StyledLabel>
          <StyledInput {...register('description')} />
          {errors.description && <p>{errors.description.message}</p>}
          <StyledLabel>Specific User IDS? Separate only with comma</StyledLabel>
          <StyledInput {...register('userIds')} />
          {errors.userIds && <p>{errors.userIds.message}</p>}

          <select
            id="deeplinkType"
            defaultValue={DropDownType.LandingPage}
            ref={deeplinkType.ref}
            name={deeplinkType.name}
            onChange={e => {
              deeplinkType.onChange(e); // react hook form onChange
            }}>
            <option value={DropDownType.LandingPage}>Landing page</option>
            <option value={DropDownType.Connect}>Connect</option>
            <option value={DropDownType.Group}>Group</option>
            <option value={DropDownType.Event}>Event</option>
            <option value={DropDownType.InviteUser}>Invite User</option>
            <option value={DropDownType.InviteUserConnect}>
              Invite User to connect
            </option>
            <option value={DropDownType.InviteUserGroup}>
              Invite User to group
            </option>
            <option value={DropDownType.InviteUserInstant}>
              Invite User to instant
            </option>
            <option value={DropDownType.InterestStatistic}>
              Interest Statistic
            </option>
          </select>
          <StyledLabel>Deeplink ID</StyledLabel>
          <StyledInput {...register('deeplinkId')} />
          {errors.deeplinkId && <p>{errors.deeplinkId.message}</p>}
          <StyledSubmitButton type="submit" />
        </StyledForm>
      </StyledContainer>
    </Scene>
  );
};
