import {Company} from 'hubbl-shared';
import {CompanyDTO} from '../../../models';

export const mapCompanyDTOToCompany = (companyDTO: CompanyDTO): Company => {
  return {
    ...companyDTO,
    created: companyDTO.created.toDate().toISOString(),
    companyOnboardingDate: companyDTO.companyOnboardingDate
      .toDate()
      .toISOString(),
  };
};
