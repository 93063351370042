import {httpsCallable} from 'firebase/functions';
import {firebaseFunctions} from './firebase';
import {HRISDepartment, HRISEmployee} from 'hubbl-shared';

const getApideckSession = async () => {
  const callableFunc = httpsCallable(
    firebaseFunctions,
    //TODO: Add to constant in shared
    'onCreateApiDeckSession',
  );
  return callableFunc()
    .then(result => {
      console.log(result.data);
      const data = result.data as {
        session_token: string;
        session_uri: string;
      };
      return data;
    })
    .catch(error => {
      console.log(`error: ${JSON.stringify(error)}`);
      throw error;
    });
};

const getAllEmployees = async () => {
  const callableFunc = httpsCallable(
    firebaseFunctions,
    //TODO: Add to constant in shared
    'getApideckEmployees',
  );
  return callableFunc()
    .then(result => {
      console.log(result.data);
      const data = result.data as HRISEmployee[];
      return data;
    })
    .catch(error => {
      console.log(`error: ${JSON.stringify(error)}`);
      throw error;
    });
};
const onIntegrationSuccess = async () => {
  const callableFunc = httpsCallable(
    firebaseFunctions,
    //TODO: Add to constant in shared
    'onIntegrationSuccess',
  );
  return callableFunc()
    .then(result => {
      console.log(result.data);
      const data = result.data as HRISDepartment[];
      return data;
    })
    .catch(error => {
      console.log(`error: ${JSON.stringify(error)}`);
      throw error;
    });
};

export const hris = {
  getApideckSession,
  getAllEmployees,
  onIntegrationSuccess,
};
