import {Palette} from 'hubbl-shared';
import {memo} from 'react';
import styled from 'styled-components';

const Button = styled.button`
  background-color: ${Palette.almostBlack};
  color: ${Palette.white};
  padding: 5px 10px;
  border-radius: 5px;
  border-width: 0px;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${Palette.primaryCherokee};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

interface PrimaryButtonProps {
  onClick: () => void;
  label: string;
}

export const PrimaryButton = memo(({label, onClick}: PrimaryButtonProps) => {
  return <Button onClick={onClick}>{label}</Button>;
});
