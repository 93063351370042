import {Palette} from 'hubbl-shared';
import {memo, useCallback, useState} from 'react';
import {Menu, Sidebar as ProSidebar, sidebarClasses} from 'react-pro-sidebar';
import sidebarBackgroundImage from '../../assets/images/sidebarBackgroundImage.png';
import {SidebarFooter} from './components/menu-footer/SidebarFooter';
import {SidebarMenuHeader} from './components/menu-header/SidebarMenuHeader';
import {MenuItemsContainer} from './components/menu-items-container/SidebarMenutItemsContainer';

export const Sidebar = memo(() => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onClickHeader = useCallback(() => setIsCollapsed(prev => !prev), []);

  return (
    <ProSidebar
      style={{
        backgroundImage: `url(${sidebarBackgroundImage})`,
        backgroundSize: 'cover',
        boxShadow: `0px 4px 24px 0px rgb(0 0 0 / 8%)`,
      }}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {},
      }}
      backgroundColor="transparent"
      collapsed={isCollapsed}>
      <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        <Menu
          style={{flex: 1}}
          menuItemStyles={{
            button: ({level, active, disabled}) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: Palette.almostBlack,
                  backgroundColor: active
                    ? Palette.whiteTransparent40
                    : undefined,
                };
            },
          }}>
          <SidebarMenuHeader
            onClick={onClickHeader}
            isCollapsed={isCollapsed}
          />

          <MenuItemsContainer isCollapsed={isCollapsed} />
        </Menu>
        <SidebarFooter isCollapsed={isCollapsed}></SidebarFooter>
      </div>
    </ProSidebar>
  );
});
