import {resetCompanyDetails, setApideckSession} from './CompanyReducer';
import {
  fetchCompany,
  addHRIStoCompany,
  fetchCompanyUsers,
} from './CompanyThunks';

export const CompanyActions = {
  setApideckSession,
  fetchCompany,
  addHRIStoCompany,
  resetCompanyDetails,
  fetchCompanyUsers,
};
