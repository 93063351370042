import {Palette} from 'hubbl-shared';
import styled from 'styled-components';
import {TextHeading8} from '../../../../text/TextHeading8';

interface SidebarGroupTitleProps {
  text: string;
  isCollapsed: boolean;
}

const SidebarGroupTitleContainer = styled.div`
  margin-left: 24px;
  margin-bottom: 8px;
  height: 16px;
`;

export const SidebarGroupTitle = ({
  text,
  isCollapsed,
}: SidebarGroupTitleProps) => {
  return (
    <SidebarGroupTitleContainer>
      {isCollapsed ? null : (
        <TextHeading8 color={Palette.almostBlackTransparent60} text={text} />
      )}
    </SidebarGroupTitleContainer>
  );
};
