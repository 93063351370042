import {CssBaseline, ThemeProvider} from '@mui/material';
import {Outlet} from 'react-router-dom';
import {Sidebar, Topbar} from '../../../components';

export const SceneAdminLandingPage = () => {
  return (
    <ThemeProvider theme={{}}>
      <CssBaseline />

      <div className="app">
        <Sidebar />
        <main className="content">
          <Topbar />
          <Outlet />
        </main>
      </div>
    </ThemeProvider>
  );
};
