import {useState} from 'react';
import styled from 'styled-components';
import {AssetIconDashboard, AssetIconEmployees} from '../../../../assets';
import {AdminWebRoute} from '../../../../routes/AdminWebRoute';
import {SidebarGroupTitle} from './components/SidebarGroupTitle';
import {SidebarItem} from './components/SidebarItem';

interface MenuItemsContainerProps {
  isCollapsed: boolean;
}

const StyledMenuItemsContainer = styled.div<MenuItemsContainerProps>`
  padding-left: ${props => (props.isCollapsed ? undefined : '0%')};
`;

export const MenuItemsContainer = ({isCollapsed}: MenuItemsContainerProps) => {
  const [currentRoute, setCurrentRoute] = useState<AdminWebRoute>(
    AdminWebRoute.Dashboard,
  );

  return (
    <StyledMenuItemsContainer isCollapsed={isCollapsed}>
      <SidebarGroupTitle isCollapsed={isCollapsed} text="General" />
      <SidebarItem
        title="Dashboard"
        to={AdminWebRoute.Dashboard}
        icon={<AssetIconDashboard />}
        currentRoute={currentRoute}
        onClick={() => setCurrentRoute(AdminWebRoute.Dashboard)}
      />

      <SidebarItem
        title="Employees"
        to={AdminWebRoute.Employees}
        icon={<AssetIconEmployees />}
        currentRoute={currentRoute}
        onClick={() => setCurrentRoute(AdminWebRoute.Employees)}
      />
      <SidebarItem
        title="Interest Statistic"
        to={AdminWebRoute.AddInterestStatistic}
        icon={<AssetIconEmployees />}
        currentRoute={currentRoute}
        onClick={() => setCurrentRoute(AdminWebRoute.AddInterestStatistic)}
      />
      <SidebarItem
        title="Hubbl Suggestions"
        to={AdminWebRoute.AddHubblSuggestion}
        icon={<AssetIconDashboard />}
        currentRoute={currentRoute}
        onClick={() => setCurrentRoute(AdminWebRoute.AddHubblSuggestion)}
      />
    </StyledMenuItemsContainer>
  );
};
