import {DocumentData, QueryDocumentSnapshot} from 'firebase/firestore';
import {User} from 'hubbl-shared';
import {UserDTO} from '../../../models';
import {mapUserDTOToUser} from './mapUserDTOToUser';

export const mapIncomingUsers = (
  userDocs: QueryDocumentSnapshot<DocumentData, DocumentData>[],
): User[] => {
  return userDocs.map(userDoc => {
    const userDocData = userDoc.data() as UserDTO;
    return mapUserDTOToUser(userDocData);
  });
};
