import {useEffect, useState} from 'react';
import {firebaseAuth} from '../api/firebase';

export const useAuthUser = () => {
  const [authUser, setAuthUser] = useState(firebaseAuth.currentUser);

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(user => {
      setAuthUser(user);
    });
  });

  return authUser;
};
